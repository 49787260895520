<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" @close="open = false; $emit('onClose')">
			<div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
					leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div
						class="relative inline-block pt-5 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
						<div class="cursor-pointer w-fit" @click="open = false; $emit('onClose')">
							<Icon name="XIcon" size="6" color="gray-600" customClass="absolute right-4 top-4" />
						</div>
						<div class="sm:flex sm:items-start p-6 mb-5">
							<div
								:class="`flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-${iconColor}-100 rounded-full sm:mx-0 sm:h-10 sm:w-10`">
								<Icon :name="icon" size="6" isOutline :color="`${iconColor}-600`" />
							</div>
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<Text size="xl" weight="medium" color="gray-900" v-html="title" />
								<Text size="sm" color="gray-500" v-html="description" class="mt-4" />
							</div>
						</div>
						<div
							class="flex flex-col sm:flex-row-reverse sm:space-x-4 bg-gray-100 border-t px-6 py-3 space-y-4 sm:space-y-0 space-x-0 sm:space-x-2 sm:space-x-reverse">
							<Button :content="yesBtnText" :isLoading="isLoading" :variant="yesBtnVariant" :onClick="() => !isLoading ? onYesClick() : {}" />
							<Button :content="noBtnText" :variant="noBtnVariant" :onClick="onNoClick" />
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'

/**
 * - Use it to show a simple modal.
 */
export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		Icon, Button, Text
	},
	props: {
		/**
		 * Use it for modal title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Use it for modal description
		 */
		description: {
			type: String,
			default: ''
		},
		/**
		 * Use it for yes button text
		 */
		yesBtnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for no button text
		 */
		noBtnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for yes button color
		 */
		yesBtnVariant: {
			type: String,
			default: 'primary'
		},
		/**
		 * Use it for no button color
		 */
		noBtnVariant: {
			type: String,
			default: 'danger'
		},
		/**
		 * Use it for modal icon
		 */
		icon: {
			type: String,
			default: null
		},
		/**
		 * Use it for modal icon color
		 */
		iconColor: {
			type: String,
			default: 'red'
		},
		/**
		 * Use it for yes button action
		 */
		onYesClick: {
			type: Function,
			default: () => {
			}
		},
		/**
		 * Use it for no button action
		 */
		onNoClick: {
			type: Function,
			default: () => {
			}
		},
		isShow: {
			type: Boolean,
			default: false
		},
		isLoading: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		isShow: function (val) {
			console.log(val, 'val')
			this.open = val;
		}
	},
	data() {
		const open = ref(this.isShow);
		return {
			open,
		}
	}
}
</script>
